<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')"  id="user-history" no-body>

    <div class="page-header">
      <b-row>
        <b-col md="12">
          <div class="filters">

            <div class="filter-column">
              <v-select
                  @input="applyFilter"
                  :clearable="false"
                  placeholder="Օգտատերեր"
                  id="vue-select"
                  :reduce="prefix => prefix.id"
                  v-model="filters.user"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="users"
                  class="companyFilterSelect"
              />

            </div>

          </div>
        </b-col>
      </b-row>
    </div>

      <b-row>
        <b-col md="12">
          <b-table
              striped
              hover
              responsive
              class="position-relative"
              :items="histories"
              :fields="fields"
          >
            <template #cell(createdAt)="data">
              {{data.value | formatDate}}
            </template>

            <template #cell(geo)="data">
              {{country_name(data.value)}}
            </template>

          </b-table>
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

            <!-- page length -->
            <b-form-group
                :label="'Գտնվել է ' + pagination.total + ' տող'"
                label-cols="3"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
            >
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                  @input="changePage"
                  v-model="pagination.current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.count"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-col>
      </b-row>

  </b-card-code>
</template>

<script>
import store from '@/store'
import usersModule from '@/store/users/usersModule'
import { BTable, BCardBody, BFormGroup, BPagination, BRow, BCol } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'

export default {
  name: 'owner-user-history',
  components: {
    BCardCode,
    BCardBody,
    BTable,
    BFormGroup,
    BPagination,
    BRow,
    BCol,
    vSelect
  },
  data () {
    return {
      USERS_STORE_MODULE_NAME: 'usersModule',
      filters: {
        user: null,
        user_type: null
      },
      fields: [
        {
          key: 'user.name',
          label: 'Օգտատեր',
        },
        {
          key: 'createdAt',
          label: 'Մուտք'
        },
        {
          key: 'user.phone',
          label: 'Հեռախոսահամար'
        },
        {
          key: 'ip',
          label: 'IP հասցե'
        },
        {
          key: 'os',
          label: 'Օպերացիոն համակարգ'
        },
        {
          key: 'browser',
          label: 'Դիտարկիչ'
        },
        {
          key: 'geo',
          label: 'Երկիր'
        }
      ],
      exportFields: [
        {
          key: 'user.name',
          label: 'Օգտատեր'
        },
        {
          key: 'createdAt',
          label: 'Արտահանում'
        },
        {
          key: 'startDate',
          label: 'Սկիզբ'
        },
        {
          key: 'endDate',
          label: 'Ավարտ'
        },
      ]
    }
  },
  computed: {
    histories () {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].histories
    },
    exports () {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].export_histories
    },
    pagination () {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].pagination
    },
    exportPagination () {
      return this.$store.state[this.USERS_STORE_MODULE_NAME].exportPagination
    },
    users () {
      return [
        {
          label: 'Բոլորը',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.USERS_STORE_MODULE_NAME].users.map(e => ({
          label: e.name,
          id: e.id
        }))
      ]
    },

    country_name () {
      return (data) => {
        if (data && data !== 'null') {
          const geoData = JSON.parse(data)
          return geoData.country
        }
        return '-'
      }
    }
  },
  created() {
    if (!store.hasModule(this.USERS_STORE_MODULE_NAME)) store.registerModule(this.USERS_STORE_MODULE_NAME, usersModule)
    this.getHistories()
    this.getUsers()
  },
  methods: {

    applyFilter () {
      this.getHistories()
    },

    getUsers() {
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/getUsers')
    },

    getHistories () {
      const filterData = {
        ...this.filters,
        current_page: this.pagination.current_page
      }
      this.$vs.loading()
      this.$store.dispatch(this.USERS_STORE_MODULE_NAME + '/getHistoriesOwner', this.serialize(filterData))
      .then(() => {
        this.$vs.loading.close()
      })
      .catch((error) => {
        this.$vs.loading.close()
        this.$root.$emit('getError', error)
      })
    },

    changePage(page) {
      this.pagination.current_page = page
      this.getHistories()
    },

    changeExportPage(page) {
      this.exportPagination.current_page = page
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#user-history {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 16rem);
    min-height: calc(var(--vh, 1vh) * 100 - 16rem);
  }
}
</style>
