import axios from '@axios'
import store from './../../store'

export default {
  namespaced: true,
  state: {
    user_types: [],
    users: [],
    pages: [],
    permissionsData: [],
    companies: [],
    userHistory: [],
    userExportHistory: [],
    histories: [],
    pagination: {
      current_page: 1,
      total: 0,
      count: 20
    },
    export_histories: [],
    exportPagination: {
      current_page: 1,
      total: 0,
      count: 20
    }
  },
  getters: {},
  mutations: {
    SET_USER_TYPES (state, data) {
      state.user_types = data
    },
    SET_USERS (state, data) {
      state.users = data
    },
    ADD_USER (state, item) {
      state.users.push(item)
    },
    EDIT_USER (state, item) {
      const index = state.users.findIndex((i) => i.id === item.id)
      if (index >= 0) {
        Object.assign(state.users[index], item)
      }
    },
    DELETE_USER (state, id) {
      const index = state.users.findIndex((i) => i.id === id)
      if (index >= 0) {
        state.users.splice(index, 1)
      }
    },
    SET_PAGES (state, data) {
      state.pages = data
    },
    SET_PERMISSIONS_DATA (state, data) {
      state.permissionsData = data
    },
    ADD_PERMISSION_ITEM (state, item) {
      state.permissionsData.push(item)
    },
    SET_COMPANIES (state, data) {
      state.companies = []
      data.map((item) => {
        let temp = {
          id: item._id,
          title: item.name
        }
        state.companies.push(temp)
      })
    },
    SET_USER_HISTORY(state, data) {
      state.userHistory = []
      state.userHistory = data
    },
    SET_USER_EXPORT_HISTORY(state, data) {
      state.userExportHistory = []
      state.userExportHistory = data
    },
    SET_USER_HISTORIES (state, data) {
      state.histories = data
    },
    SET_PAGINATION(state, data) {
      state.pagination = data
    },
    SET_EXPORT_HISTORIES(state, data) {
      state.export_histories = data
    },
    SET_EXPORT_PAGINATION(state, data) {
      state.exportPagination = data
    }
  },
  actions: {

    getUserTypes ({commit}) {
      return new Promise(((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        const url = (userType === 'ADMIN') ? 'user-types' : 'tso-user-types'
        axios.get(url)
          .then((response) => {
            commit('SET_USER_TYPES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getUsers ({commit}, query) {
      return new Promise(((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        let url = (userType === 'ADMIN') ? 'users' : 'tso-users'
        if (query) {
          url += '?' + query
        }
        axios.get(url)
          .then((response) => {
            commit('SET_USERS', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    addUser ({commit}, data) {
      return new Promise(((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        const url = (userType === 'ADMIN') ? 'users' : 'tso-users'
        axios.post(url, data)
          .then((response) => {
            commit('ADD_USER', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    editUser ({commit}, data) {
      return new Promise(((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        const url = (userType === 'ADMIN') ? 'users/' : 'tso-users/'
        axios.put(url + data.id, data)
          .then((response) => {
            commit('EDIT_USER', response.data.item)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    deleteUser ({commit}, id) {
      return new Promise(((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        const url = (userType === 'ADMIN') ? 'users/' : 'tso-users/'
        axios.delete(url + id)
          .then((response) => {
            commit('DELETE_USER', id)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }))
    },

    getCompanies ({commit}) {
      return new Promise((resolve, reject) => {
        const userType  = atob(store.state.user_type)
        const url = (userType === 'ADMIN') ? 'companies' : 'tso-companies'
        axios.get(url)
          .then((response) => {
            commit('SET_COMPANIES', response.data.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getUserHistory ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`user-history/${id}`)
          .then(response => {
            commit('SET_USER_HISTORY', response.data.data)
            commit('SET_USER_EXPORT_HISTORY', response.data.exports)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getHistories ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`user-history?${query}`)
          .then(response => {
            commit('SET_USER_HISTORIES', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getHistoriesOwner ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`owner-user-history?${query}`)
          .then(response => {
            commit('SET_USER_HISTORIES', response.data.data)
            commit('SET_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getExportHistories ({commit}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`user-export-history?${query}`)
          .then(response => {
            commit('SET_EXPORT_HISTORIES', response.data.data)
            commit('SET_EXPORT_PAGINATION', response.data.pagination)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  },
}
